import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/');
        }
    }, [navigate]);


    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);  // İstek sırasında loading state'ini true yapın
        try {
            const response = await login({ UserName: username, Password: password });
            localStorage.setItem('token', response.data.token); // Token'ı localStorage'a kaydet
            setMessage('Login successful');
            setTimeout(() => { window.location.reload() }, 3000);
        } catch (error) {
            setError('Login failed');
        }
        setLoading(false);  // İstek tamamlandığında loading state'ini false yapın
    };

    const handleRegisterRedirect = () => {
        navigate('/register'); // Kayıt sayfasına yönlendir
    };

    const handleForgotPasswordRedirect = () => {
        navigate('/forgotPassword');
    };

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center " style={{ width: '100%', maxWidth: '500px', marginRight: '620px', marginTop : '100px' }}>
            <h2>Login</h2>
            <Form onSubmit={handleLogin} style={{ width: '100%', maxWidth: '400px'}}>
                <Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={loading} className="me-2">
                    {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Login'}
                </Button>
                <Button variant="secondary" onClick={handleRegisterRedirect}>
                    Register
                </Button>
                <Button variant="link" onClick={handleForgotPasswordRedirect}>
                    Forgot Password?
                </Button>
            </Form>
            {message && <Alert variant="success" className="mt-3">{message}</Alert>}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </Container>
    );
};

export default Login;
