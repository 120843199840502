import axios from 'axios';


const api = axios.create({
    baseURL:  'https://text4reach.com/api', // Backend API URL'nizi burada belirtin
    timeout: 60000, // 10 saniye timeout süresi
    headers: {
        'Content-Type': 'application/json', // İstek başlığına JSON tipi ekleyin
        'Accept': 'application/json' // Yanıtın JSON olmasını bekleyin
    }
});

export const login = async (data) => {
    return api.post(`/User/Login`, data);
};

export const register = async (data) => {
    //return api.post(`/User/Register`, data);
};

export const forgotPassword = async (data) => {
    return api.post(`/User/ForgotPassword`, data);
};

export const forgotPasswordVerify = async (data) => {
    return api.post(`/User/ForgotPasswordVerify`, data);
};

export const passwordUpdate = async (data) => {
    return api.post(`/User/PasswordUpdate`, data);
};

export const emailVerify = async (data) => {
    return api.post(`/User/EmailVerify`, data);
};

// Müşteri Listeleme (Get All Customers)
export const filterCustomers = async (data) => {
    const token = localStorage.getItem('token');
    return api.post(`/Customer/GetAllCustomers`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

// Müşteri Ekleme (Create Customer)
export const createCustomer = async (data) => {
    const token = localStorage.getItem('token');
    return api.post(`/Customer`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

// Müşteri Güncelleme (Update Customer)
export const updateCustomer = async (id, data) => {
    const token = localStorage.getItem('token');
    return api.put(`/Customer/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

// Müşteri Silme (Delete Customer)
export const deleteCustomer = async (id) => {
    const token = localStorage.getItem('token');
    return api.delete(`/Customer/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

// Müşterileri Kategoriye Ekleme (Add Customers to Category)
export const addCustomersToCategory = async (data) => {
    const token = localStorage.getItem('token');
    return api.post(`/Customer/AddCustomersToCategory`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

// Müşterileri Gruptan Çıkarma (Add Customers to Group)
export const addCustomersToGroup = async (data) => {
    const token = localStorage.getItem('token');
    return api.post(`/Customer/AddCustomersToGroup`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

// Müşterileri Kategoriden Çıkarma (Delete Customers from Category)
export const deleteCustomersFromCategory = async (data) => {
    const token = localStorage.getItem('token');
    return api.post(`/Customer/DeleteCustomersFromCategory`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

// Müşterileri Gruptan Çıkarma (Delete Customers from Group)
export const deleteCustomersFromGroup = async (data) => {
    const token = localStorage.getItem('token');
    return api.post(`/Customer/DeleteCustomersFromGroup`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const getCategories = async () => {
    const token = localStorage.getItem('token');
    return api.get(`/Category`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const createCategory = (data) => {
    const token = localStorage.getItem('token');
    return api.post('/Category', data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const updateCategory = (data) => {
    const token = localStorage.getItem('token');
    return api.put(`/Category`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const deleteCategory = (id) => {
    const token = localStorage.getItem('token');
    return api.delete(`/Category/${id}`,{
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const getGroups = async () => {
    const token = localStorage.getItem('token');
    return api.get(`/Group`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const createGroup = (data) => {
    const token = localStorage.getItem('token');
    return api.post('/Group', data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const updateGroup = (data) => {
    const token = localStorage.getItem('token');
    return api.put(`/Group`, data,  {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const deleteGroup = (id) => {
    const token = localStorage.getItem('token');
    return api.delete(`/Group/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const getCustomerReferences = async () => {
    const token = localStorage.getItem('token');
    return api.get(`/Enum/CustomerReferences`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const uploadExcelFile = (formData) => {
    const token = localStorage.getItem('token');
    return api.post('/Customer/UploadExcel/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}` 
        },
    });
};

export const createMessage = (data) => {
    const token = localStorage.getItem('token');
    return api.post('/Message', data, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' , 'Accept': 'application/json' },
        timeout: 120000
    });
};

export const updateMessage = (data) => {
    const token = localStorage.getItem('token');
    return api.put('/Message', data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const deleteMessage = (id) => {
    const token = localStorage.getItem('token');
    return api.delete(`/Message/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const sendMessage = (data) => {
    const token = localStorage.getItem('token');
    return api.post('/Message/SendMessage', data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const getMessageTypes = async () => {
    const token = localStorage.getItem('token');
    return api.get(`/Enum/MessageTypes`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const getMessages = async (data) => {
    const token = localStorage.getItem('token');
    return api.post(`/Message/GetAllMessages`, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
};
