import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordVerify } from '../../api';

const ForgotPasswordVerify = () => {
    const [token, setToken] = useState(new URLSearchParams(window.location.search).get('token') || '');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            if (token) {
                try {
                    const response = await forgotPasswordVerify({ token });
                    if (response.data.isSuccess) {
                        navigate(`/passwordUpdate?token=${token}`);
                    } else {
                        setMessage(response.data.message);
                    }
                } catch (error) {
                    setMessage('Token verification failed');
                }
            } else {
                setMessage('Token is required');
            }
        };

        verifyToken();
    }, [token, navigate]);

    return (
        <div>
            <h2>Verify Token</h2>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ForgotPasswordVerify;
