import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  getGroups,
} from "../../api";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
} from "react-bootstrap";

const GroupPage = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn !== null) {
      isLoggedInFunc();
      fetchGroups();
    }
  }, [isLoggedIn]);

  const isLoggedInFunc = () => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await getGroups();
      console.log(response.data.object);
      setGroups(response.data.object);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const handleCreateGroup = async () => {
    try {
      await createGroup({ name: newGroupName });
      setNewGroupName("");
      setShowCreateModal(false);
      fetchGroups();
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleUpdateGroup = async (id) => {
    try {
      await updateGroup({ id: id, name: selectedGroup.name });
      setSelectedGroup(null);
      setShowEditModal(false);
      fetchGroups();
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  const handleDeleteGroup = async (id) => {
    const confirm = window.confirm(
      "Bu grubu silmek istediğinize emin misiniz?"
    );
    if (!confirm) {
      return; // Kullanıcı işlemi onaylamazsa hiçbir şey yapma
    }
    try {
      await deleteGroup(id);
      fetchGroups();
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4" style={{ marginRight: "290px" }}>
        Group Management
      </h2>

      <Button
        variant="primary"
        onClick={() => setShowCreateModal(true)}
        className="mb-3"
      >
        Create Group
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(groups) &&
            groups.map((group) => (
              <tr key={group.id}>
                <td>{group.name}</td>
                <td>
                  <Button
                    variant="warning"
                    className="me-2"
                    onClick={() => {
                      setSelectedGroup(group);
                      setShowEditModal(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteGroup(group.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupNameCreate">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter group name"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateGroup}>
            Create Group
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupNameEdit">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter group name"
                value={selectedGroup ? selectedGroup.name : ""}
                onChange={(e) =>
                  setSelectedGroup({
                    ...selectedGroup,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateGroup(selectedGroup.id)}
          >
            Update Group
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default GroupPage;
