import React, { useState } from 'react';
import { forgotPassword } from '../../api';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';


const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            const response = await forgotPassword({ Email: email });

            setMessage(response.data?.message);

        } catch (error) {
            setError(error.response?.data?.message);

        } 
            
        setLoading(false);
        
    };

    return (
        <Container  className="d-flex flex-column justify-content-center align-items-center" style={{ width: '100%', maxWidth: '500px', marginRight: '620px', marginTop : '100px' }}>
            <h2>Forgot Password</h2>
            <Form onSubmit={handleForgotPassword} style={{ width: '100%', maxWidth: '400px' }}>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Send Reset Link'}
                </Button>
            </Form>
            {message && <Alert variant="success" className="mt-3">{message}</Alert>}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </Container>
    );
};

export default ForgotPassword;
