import React, { useState, useEffect } from "react";
import { ListGroup, Collapse, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa"; // İkon için react-icons kütüphanesini kullandım

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div
      style={{
        width: open ? "250px" : "60px",
        position: "fixed",
        top: "60px",
        left: "0",
        bottom: "0",
        backgroundColor: "dark",
        padding: open ? "10px" : "10px 0",
        transition: "width 0.3s ease-in-out",
        marginTop: "80px",
      }}
    >
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="menu-collapse"
        aria-expanded={open}
        variant="primary"
        className="w-100 mb-2"
      >
        {open ? "Menü" : <FaBars />} {/* Eğer menü kapalıysa simge göster */}
      </Button>
      <Collapse in={open}>
        <div id="menu-collapse">
          <ListGroup>
            <ListGroup.Item
              action
              onClick={() => handleNavigation("/customers")}
            >
              Customer Management
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => handleNavigation("/categories")}
            >
              Category Management
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleNavigation("/groups")}>
              Group Management
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => handleNavigation("/messages")}
            >
              Message Management
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Collapse>
    </div>
  );
};

export default Sidebar;
