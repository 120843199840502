import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { emailVerify } from '../../api';

const EmailVerify = () => {

    const [email, setEmail] = useState(new URLSearchParams(window.location.search).get('email') || '');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    
    useEffect(() => {
        const verifyEmail = async () => {
            if (email) {
                try {
                    const response = await emailVerify({ email });
                    if (response.data.isSuccess) {
                        setMessage(response.data.message);
                        setTimeout(() => { navigate('/login'); }, 3000);
                    } else {
                        setMessage(response.data.message);
                    }
                } catch (error) {
                    setMessage('Email verification failed');
                }
            } else {
                setMessage('Email is required');
            }
        };

        verifyEmail();
    }, [email, navigate]);

    return (
        <div>
            <h2>Verify Email</h2>
            {message && <p>{message}</p>}
        </div>
    );

};

export default EmailVerify;