import React, { useState , useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { passwordUpdate } from "../../api";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";

const PasswordUpdate = () => {
  const [token, setToken] = useState(
    new URLSearchParams(window.location.search).get("token") || ""
  );
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (!token) {
      setError("Invalid or missing token");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);
    try {
      const response = await passwordUpdate({
        NewPassword: newPassword,
        Token: token,
      });
      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      setError(error.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      {(isLoggedIn || token) && (
        <Container className="d-flex flex-column justify-content-center align-items-center min-vh-100">
          <h2>Update Password</h2>
          <Form
            onSubmit={handlePasswordUpdate}
            style={{ width: "100%", maxWidth: "400px" }}
          >
            <Form.Group className="mb-3" controlId="formNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formConfirmPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="me-2"
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Update Password"
              )}
            </Button>
          </Form>
          {message && (
            <Alert variant="success" className="mt-3">
              {message}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </Container>
      )}
    </>
  );
};

export default PasswordUpdate;
