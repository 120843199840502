import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from 'react-router-dom';
import {
  createMessage,
  getMessages,
  deleteMessage,
  updateMessage,
  getMessageTypes,
  sendMessage,
  getGroups,
  getCategories,
} from "../../api";
import { Container, Button, Table, Modal, Form } from "react-bootstrap";

const MessagePage = () => {
  const [messageText, setMessageText] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [messages, setMessages] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageType, setMessageType] = useState("");
  const [selectedGroup, setGroup] = useState("");
  const [selectedCategory, setCategory] = useState("");
  const [groups, setGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [messageTypes, setMessageTypes] = useState([]);
  const [filters, setFilters] = useState({
    messageType: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);  // Bu asenkron bir işlem
    } else {
      setIsLoggedIn(false);
    }
  }, []); // Boş bağımlılık dizisiyle sadece ilk renderda çalışır

  // İkinci useEffect: isLoggedIn ve messageType değişimlerini izler
  useEffect(() => {
    if (isLoggedIn !== null) {
      isLoggedInFunc();  // isLoggedIn güncellendikten sonra çalışır
      fetchMessages();
      fetchMessageTypes();
      fetchGroups();
      fetchCategories();
    }
  }, [isLoggedIn, messageType]); 

  const isLoggedInFunc = () => {
    if (!isLoggedIn) {
      console.log("func login")
      console.log(isLoggedIn)
      navigate("/login");
    }
  };

  const fetchMessages = async () => {
    try {
      const filterPayload = {
        type: filters.messageType ? parseInt(filters.messageType) : null,
      };

      const response = await getMessages(filterPayload);
      setMessages(response.data.object);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await getGroups();
      console.log(response.data.object);
      setGroups(response.data.object);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      setCategories(response.data.object);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCreateMessage = async () => {
    try {
      const newMessage = {
        text: messageType === "Sms" ? messageText.replace(/<\/?[^>]+(>|$)/g, "") : messageText,
        type: messageType,
        emailSubject: messageType === "Email" ? emailSubject : null,
      };
      console.log(newMessage);
      await createMessage(newMessage);
      setShowCreateModal(false);
      setMessageText("");
      setEmailSubject("");
      setMessageType("");
      setSelectedMessage("");
      setSelectedMessage(null);
      fetchMessages();
    } catch (error) {
      console.error("Error saving message:", error);
    }
  };

  const handleCreateClose = async () => {
    setShowCreateModal(false);
    setMessageText("");
    setEmailSubject("");
    setMessageType("");
    setSelectedMessage("");
    setSelectedMessage(null);
    fetchMessages();
  };

  const handleEditClose = async () => {
    setShowEditModal(false);
    setMessageText("");
    setEmailSubject("");
    setMessageType("");
    setSelectedMessage("");
    setSelectedMessage(null);
    fetchMessages();
  };

  const handleEditMessage = (message) => {
    setSelectedMessage(message);
    setMessageText(message.text);
    setMessageType(message.type);
    if (message.type === "Email") {
      setEmailSubject(message.emailSubject);
    }

    setShowEditModal(true);
  };

  const handleSendClose = async () => {
    setShowSendMessageModal(false);
    setMessageText("");
    setEmailSubject("");
    setMessageType("");
    setSelectedMessage(null);
    setCategory("");
    setGroup("");
    fetchMessages();
  };

  const handleSendMessageModal = (message) => {
    setSelectedMessage(message);

    setShowSendMessageModal(true);
  };

  const handleSendMessage = async () => {
    try {
      const messageSend = {
        messageId: selectedMessage.id,
        categoryId: selectedCategory !== "" ? parseInt(selectedCategory) : null,
        groupId: selectedGroup !== "" ? parseInt(selectedGroup) : null,
      };

      console.log(selectedGroup);
      setShowSendMessageModal(false);
      await sendMessage(messageSend);
      setMessageText("");
      setEmailSubject("");
      setMessageType("");
      setSelectedMessage(null);
      setCategory("");
      setGroup("");
      fetchMessages();
    } catch (error) {
      console.error("Error saving message:", error);
    }
  };
  /*
  React.useEffect(() => {
    if (selectedMessage) {
      console.log("Message Type after state update:", messageType);
      // Diğer işlemler burada yapılabilir
    }
  }, [messageType]);
  */
  const handleUpdateMessage = async () => {
    try {
      const updatedMessage = {
        ...selectedMessage,
        text: messageType === "Sms" ? messageText.replace(/<\/?[^>]+(>|$)/g, "") : messageText,
        type: messageType,
        emailSubject: messageType === "Email" ? emailSubject : null,
      };
      await updateMessage(updatedMessage);
      setShowEditModal(false);
      setMessageText("");
      setEmailSubject("");
      setMessageType("");
      setSelectedMessage("");
      setSelectedMessage(null);
      fetchMessages();
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  const handleDeleteMessage = async (id) => {
    try {
      await deleteMessage(id);
      fetchMessages();
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    fetchMessages();
  };

  const fetchMessageTypes = async () => {
    try {
      const response = await getMessageTypes(); // Kategori API endpoint'inizi kontrol edin
      setMessageTypes(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"], // Image ve video ekleme seçenekleri eklendi
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["clean"], // Removes all formatting
    ],
  };

  const quillFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "align",
  ];

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4" style={{ marginRight: "290px" }}>
        Message Management
      </h2>

      <Button variant="primary" onClick={() => setShowCreateModal(true)}>
        Create Message
      </Button>

      <Form.Group controlId="formFilterMessageType" className="mb-3">
        <Form.Label>Mesaj Türüne Göre Filtrele</Form.Label>
        <Form.Control
          as="select"
          name="messageType"
          value={filters.messageType}
          onChange={handleFilterChange}
        >
          <option value="">Select Message Type</option>
          {Array.isArray(messageTypes) &&
            messageTypes.map((messageType) => (
              <option key={messageType.value} value={messageType.value}>
                {messageType.name}
              </option>
            ))}
        </Form.Control>
      </Form.Group>

      <Button variant="primary" onClick={handleSearch}>
        Search
      </Button>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Text</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(messages) &&
            messages.map((message) => (
              <tr key={message.id}>
                <td>
                  <div
                    dangerouslySetInnerHTML={{ __html: message.text }}
                    style={{
                      maxWidth: "750px",
                      maxHeight: "350px",
                      overflow: "hidden",
                      objectFit: "contain",
                      display: "block",
                      margin: "0 auto",
                    }}
                  ></div>
                </td>
                <td>{message.type}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => handleSendMessageModal(message)}
                    className="me-2"
                  >
                    Send
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => handleEditMessage(message)}
                    className="me-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteMessage(message.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {/* Create Message Modal */}
      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formMessageType">
            <Form.Label>Message Type</Form.Label>
            <Form.Control
              as="select"
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
            >
              <option value="">Select Message Type</option>
              {Array.isArray(messageTypes) &&
                messageTypes.map((messageType) => (
                  <option key={messageType.value} value={messageType.name}>
                    {messageType.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          {messageType === "Email" && (
            <Form.Group controlId="formEmailSubject" className="mt-3">
              <Form.Label>Email Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Form.Group>
          )}

          <Form.Group controlId="formMessageText" className="mt-3">
            <Form.Label>Message Text</Form.Label>
            <ReactQuill
              value={messageText}
              onChange={setMessageText}
              placeholder="Write your message here..."
              modules={quillModules}
              formats={quillFormats}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateMessage}>
            Save Message
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Message Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formMessageType">
            <Form.Label>Message Type</Form.Label>
            <Form.Control
              as="select"
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
            >
              <option value="">Select Message Type</option>
              {Array.isArray(messageTypes) &&
                messageTypes.map((type) => (
                  <option key={type.value} value={type.name}>
                    {type.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          {messageType === "Email" && (
            <Form.Group controlId="formEmailSubject" className="mt-3">
              <Form.Label>Email Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Form.Group>
          )}

          <Form.Group controlId="formMessageText" className="mt-3">
            <Form.Label>Message Text</Form.Label>
            <ReactQuill
              value={messageText}
              onChange={setMessageText}
              placeholder="Write your message here..."
              modules={quillModules}
              formats={quillFormats}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateMessage}>
            Update Message
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSendMessageModal}
        onHide={() => setShowSendMessageModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCategory === "" && (
            <Form.Group controlId="formGroupSelection">
              <Form.Label>Group</Form.Label>
              <Form.Control
                as="select"
                value={selectedGroup}
                onChange={(e) => setGroup(e.target.value)}
              >
                <option value="">Select Group</option>
                {Array.isArray(groups) &&
                  selectedCategory === "" &&
                  groups.map((group) => (
                    <option key={group.value} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          )}
          {selectedGroup === "" && (
            <Form.Group controlId="formCategorySelection">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={selectedCategory}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                {Array.isArray(categories) &&
                  selectedGroup === "" &&
                  categories.map((category) => (
                    <option key={category.value} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSendClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendMessage}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MessagePage;
